import {io} from "socket.io-client"
import { showToast } from "../utils";

// options
const option = {
   closeOnBeforeunload:false,
};

// // console.log("process.env.REACT_APP_SOCKET_URL", import.meta.env.VITE_SOCKET_URL);
// ** socket client instance


let socket =  io(process.env.REACT_APP_LUDO_SOCKET_URL, option);

async function sendRequest(key, dataToSend, callback) {
  if(socket) {
    socket.emit(key, dataToSend, callback)
  }
}



// // console.log("process.env.REACT_APP_SOCKET_URL", import.meta.env.VITE_SOCKET_URL);

export {sendRequest,socket}



