import React from 'react'
import { useEffect, useId, useMemo, useState } from "react";
import WinLossModalWrapper from "../../../../components/Modal/WinLossModalWrapper";
import coinPng from "../../../../assets/coinGroup.svg";
import kingSvg from "../../../../assets/king.svg";
import botSvg from "../../../../assets/bot.png";
import user from "../../../../assets/user.svg";
import FlexWrapper from "../../../../components/Wrapper/FlexWrapper";
import CImage from "../../../../components/Img/CImage";
import Text from "../../../../components/Wrapper/Text";
import Button from "../../../../components/button/Button";
import { useHistory } from "react-router-dom";
import Confetti from "react-confetti";
import { publish } from "../../../../CustomEvents/event";
import { LeaveRoom, raiseEvent } from "../../../../constants/socketKeys";
import { socket } from "../../../../socket/socket";
import { useSelector } from "react-redux";
import {
  getSessionStorageItem,
  setSessionStorageItem,
} from "../../../../utils/sessionStorageUtils";

import useParamsChange from "../../../../hooks/useParamsChange";
import { gameSound } from "../../../../../../../App";
import { getGameDetails } from '../../../../feature/service/gameService';

function WinLossModal({
  modalShow,
  onClose,
  winners,
  resetTokenScale,
  freePlayWinners,
  setWinners,
  resetGame,
  openRematchModalPlayer,
}) {
  const [winPlayers, setPlayers] = useState([]);
  const [looser, setLooser] = useState(false);
  const [winDetails, setDetails] = useState([]);

  const navigate = useHistory();
  const [param,setSearchParam] = useParamsChange();
  const rematch = param.get("rematch");
  const { userData, gameMode, gameObj } = useSelector((state) => state.game);
  const isFreePlay =
    gameMode?.name === "Computer" ||
    getSessionStorageItem("gameMode") === "Computer";

  // update winner list
  useEffect(() => {
    console.log("winners list >>>>>", winners);
    if (winners?.length > 0 && isFreePlay === false) {
      const winnerList = winners.map((winner, idx) => {
        return {
          ...winner,
          id: idx + 1,
          winner: idx == 0 ? true : false,
          looser: winners.length - 1 === idx ? true : false,
          winAmmount: "+300",
        };
      });
      const findWinner = winnerList.find((player) => player?.winner);
      // const isYouWon = winnerList.find(player => player?.uid === userData?.uid)
      if (findWinner.uid === userData?.uid) {
        setLooser(findWinner);
      } else {
        console.log("findWinner>>>", findWinner);
        setLooser({ winner: false, color: findWinner?.color });
      }
      // console.log("winnerList",winnerList, findLooser)
      setPlayers(winnerList);
    } else if (isFreePlay) {
      const findWinner = winners.find((win) => win.winner);
      // console.log("findWinner>>>>>", freePlayWinners);
      if (findWinner?.color === gameObj?.token) {
        setLooser({ winner: true, color: findWinner?.color });
      } else {
        setLooser({ winner: false, color: findWinner?.color });
      }
      setPlayers(winners);
    }

    if (modalShow) resetTokenScale();
  }, [freePlayWinners, freePlayWinners.length, gameObj?.token, isFreePlay, modalShow, resetTokenScale, userData?.uid, winners]);

  // ** PLAY WINING LOOS SOUND
  // show start game image
  useEffect(() => {
    if (modalShow) {
      resetGame();
      if (looser?.winner) {
        gameSound.playWinSound();
      } else {
        gameSound.playLooseSound();
      }
      //** get game details */
       // get win details
       getGameDetails(param.get("roomName"))
       .then(response => {
         const detail = response?.result;
         console.log("win details", response);
         setDetails(detail?.players)
       }).catch(err => {
          console.log("error", err);
       })
    }
  }, [looser?.winner, modalShow]);

 
  // set winning amount
  useEffect(() => {
     if(modalShow && winPlayers?.length && winDetails?.length) {
         let updatedPlayers = winPlayers?.map(player => {
            // ** find player for details array
            let  getPlayer = winDetails?.find(detail => detail?.userId === player?.userId);
            // ** add player win amount
            player.winingAmount = getPlayer?.winningAmount;
            return player;
         });
         setPlayers(updatedPlayers);
     }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[modalShow, winDetails])



  const updateParams = (newParam={}) => {
    setSearchParam({
      roomName: param.get("roomName"),
      uid: param.get("uid"),
      started: false,
      ...newParam
    });
  }

  // // console.log("winPlayers",winners)
  const goToMenu = () => {
    navigate.push('/qwertyuiop/menu');
    publish(LeaveRoom, { roomname: param.get("roomName") });
    setWinners([]);
    setSessionStorageItem("localWinners", null);
    setSessionStorageItem("playerLeft", null);
    onClose();
  };

  //** handle rematch option */
  const handleRematch = () => {
    openRematchModalPlayer();
    updateParams({rematch : true});

    const rematchRaiseEventObj = {
      roomName: param.get("roomName"),
      EventCode: 5,
      SendTo: "Other",
      CustomData: {
        sendingUser: socket.id,
        userName: userData?.nickname,
        uid: userData?.uid,
      },
    };

    publish(raiseEvent, rematchRaiseEventObj);
  };

  // filter connected players
  const connectedWinnerPlayer = useMemo(() => {
    if (rematch === "true") {
      return winPlayers?.filter((player) => player?.isConnected);
    }
    return winPlayers;
  }, [rematch, winPlayers]);

  return (
    <div>
      {modalShow && looser?.winner && looser?.color === gameObj?.token && (
        <Confetti
          width={window.clientWidth}
          height={window.clientHeight}
          numberOfPieces={500}
          gravity={0.09}
          recycle={true}
        />
      )}

      {/* w */}
      <WinLossModalWrapper
        modalShow={modalShow}
        customStyles={{
          width: "30%",
          height: "50%",

        }}
        ribbenText="Result"
        id="win-loss-modal"
        looserPlayer={looser}
      >
        {/* played playerd */}
        <ul className="p-2 h-5/6 overflow-y-auto">
          {connectedWinnerPlayer?.map(
            (
              {
                id = null,
                winner,
                nickname,
                winingAmount = "",
                looser,
                isBot = false,
              },
              idx
            ) => (
              <li
                className={`${
                  winner
                    ? "bg-[#006AFF]"
                    : "border-2 border-[#e4ab0f] rounded-md"
                } rounded flex justify-between items-center gap-x-2 py-2 px-4 my-2`}
                key={idx}
              >
                <div className="flex items-center gap-2">
                  {winner ? (
                    <div className="relative">
                      <CImage url={kingSvg} width="10" />
                      <Text
                        title={idx + 1}
                        color="text-white text-sm md:text-2xl font-bold absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2"
                      />
                    </div>
                  ) : (
                    <div className="text-center w-16">
                      <Text
                        title={idx + 1}
                        color="text-white text-sm md:text-xl font-bold"
                      />
                    </div>
                  )}
                  <div className="md:scale-125">
                    <CImage url={isBot ? botSvg : user} width="12" />
                  </div>
                </div>
                <div>
                  <Text
                    title={nickname}
                    color="text-white text-xs md:text-xl font-light"
                  />
                </div>
                {isFreePlay === false && (
                  <FlexWrapper className={"gap-x-1"}>
                    <span className="text-xl font-bolder text-white">
                      
                    </span>
                    <CImage url={coinPng} width="10" />
                    <Text title={winingAmount} color="text-white" />
                  </FlexWrapper>
                )}
              </li>
            )
          )}
        </ul>
        <div className="footer flex justify-between gap-5  px-3 py-3">
          <Button
            text="Menu"
            className="bg-indigo-600 text-white outline-2 outline-[#FFB902] w-full text-xl rounded-xl menu-button"
            handleClick={goToMenu}
          />
          {isFreePlay === false && (
            <Button
              handleClick={handleRematch}
              text="Rematch"
              className="text-white py-3 w-full shadow-lg shadow-[#FFB902] text-xl rounded-xl "
            />
          )}
        </div>
      </WinLossModalWrapper>
    </div>
  );
}

export default WinLossModal;
