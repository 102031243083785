import React, { useContext, useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import TopBar from "./TopBar";
import Footer from "./Footer";
import { AuthContext } from "../../context/Auth";
import { Helmet } from "react-helmet";
const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: "#141627",
    position: "relative",
    zIndex: "9",
  },
  MainLayout: {
    minHeight: "calc(100vh - 415px)",
  },
}));

const MainLayout = ({ children }) => {
  const classes = useStyles();
  const history = useHistory();
  const [currentUrl, setCurrentUrl] = useState("")

  useEffect(() => {
    setCurrentUrl(window.location.href)
  }, [window.location.href])


  const { getUserWallletApi } = useContext(AuthContext);


  // <script id="ze-snippet" src="https://static.zdassets.com/ekr/snippet.js?key=f3c3ec83-a4d4-4f80-906e-9e3b568f54c0"> </script>




  const loadZendeskScript = () => {
    if (document.getElementById('ze-snippet')) return;
    const script = document.createElement('script');
    script.id = 'ze-snippet';
    script.src = 'https://static.zdassets.com/ekr/snippet.js?key=f3c3ec83-a4d4-4f80-906e-9e3b568f54c0';
    script.async = true;
    script.onload = () => {
      window.zE('messenger', 'show');
      window.zE('messenger', 'open');
    };
    document.head.appendChild(script);
  };

  useEffect(() => {
    loadZendeskScript()
  }, [])




  useEffect(() => {
    getUserWallletApi();
  }, [])
  return (
    <div className={classes.root}>
      <Helmet>
        <meta property="og:title" content="BetByHeart" />
        <meta property="og:type" content="website" />
        <meta property="og:url" content={currentUrl} />
        <meta property="og:image" content="https://betbyheart.com/images/favicon.png" />
        <meta property="og:description" content="Join BetByHeart with this referral link!" />
        <meta property="og:site_name" content="BetByHeart" />

        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:site" content="@betbyheart" />
        <meta name="twitter:title" content="Referral - BetByHeart" />
        <meta name="twitter:description" content="Join BetByHeart with this referral link!" />
        <meta name="twitter:image" content="https://betbyheart.com/images/favicon.png" />
      </Helmet>
      <TopBar />
      <div
        style={
          history.location.pathname !== "/"
            ? { display: "block" }
            : { display: "none" }
        }
      ></div>

      <div className={classes.MainLayout}>{children}</div>
      <Footer />
    </div>
  );
};

export default MainLayout;
