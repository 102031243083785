import React from 'react'
import ModalWrapper from '../../../../components/Modal/ModalWrapper';
import CoinCounterCard from '../CoinCounterCard';
import Button from '../../../../components/button/Button';
import { useRoomListing } from "../../../../hooks/useRoomListing";
import {
  generateRandomRoomName,
  getRandomColor,
  getRandomColorByExistingColor,
  getRandomPlayerRoomCount,
  getRandomeBoard,
  showToast,
} from "../../../../utils";
import { publish } from "../../../../CustomEvents/event";
import { ludo_createRoom, joinRoom } from "../../../../constants/socketKeys";
import { Navigate, useHistory } from "react-router-dom";
import { getSessionStorageItem, resetSessionStorage, setSessionStorageItem } from "../../../../utils/sessionStorageUtils";
import {
  setGameObj,
  setGameType,
  setSingleLobbyData,
} from "../../../../feature/slice/gameSlice";
import { useDispatch, useSelector } from 'react-redux';


function QuickTypeModal({
    modalShow,
    onClose,
}) {
    const [rooms] = useRoomListing(modalShow);
    const dispatch = useDispatch();
    const navigate = useHistory();
    const {gameObj} = useSelector(state => state.game);
    const prevJoinedGame = getSessionStorageItem('savePrevQuickPlay')

     // ** createQuickPlayGame
  const createQuickPlayGame = () => {
    console.log("rooms>>>",rooms)
    const visibleLobbies = rooms.find((room) => {
      let lobbyData = room.roomCustomPropertiesForLobby;
      
      // ** Check current game is under quick player
      let isQuickPlay = lobbyData?.game_type === "Quick Play";
      
      // ** Check current roomAmount is equalant to our selected ammount
      let roomAmmountMatch = lobbyData?.roomAmmount === gameObj?.feeAmmount;

      if ( isQuickPlay &&  roomAmmountMatch && room?.maxPlayers !== room?.roomPlayers && prevJoinedGame !== room?.roomname) {
        return room;
      }
    });

    // console.log("visibleLobbies",visibleLobbies)
    if (!visibleLobbies) {
      createNewGame(true);
    } else {
      findAndPlay(visibleLobbies);
    }
  };

  //** dispatch state  for existing lobby */
  const dispatchStateForExistingGame = (data, visibleLobbies) => {
    return new Promise((resolve, reject) => {
      try {
        dispatch(setGameObj(data));
        // save single lobby data
        dispatch(setSingleLobbyData(visibleLobbies));
        resolve(true);
      } catch (error) {
        reject(error);
        // console.log("error");
      }
    });
  };

  //** findAndPlay */
  const findAndPlay = async (visibleLobbies) => {
    const getlobby = visibleLobbies?.roomCustomPropertiesForLobby;
    // if lobby is present then play with existing otherwise create new
    // console.log("visibleLobbies2", getlobby);
    const obj = {
      token: getRandomColorByExistingColor(getlobby.tokenArr),
      inGamePlayer: visibleLobbies?.maxPlayers,
      feeType: "coin",
      feeAmmount: gameObj?.feeAmmount,
      selectedBoard: getlobby.boardType,
    };

    // save game object
    dispatchStateForExistingGame(obj, visibleLobbies)
      .then((success) => {
        if (success) {
          handleJoinRoom(visibleLobbies);
        }
      })
      .catch((err) => {
        // console.log("err", err);
      });
   };

  //** handle join rooms */
  const handleJoinRoom = (getlobby) => {
    const joinRoomData = {
      roomname: getlobby?.roomname,
      nickname: "user019223",
      roomPassword: "abc123",
    };

    // console.log("Joining in 135")
    //** publish room */
    // publish(joinRoom, joinRoomData);

    //** clear out session */
    resetSessionStorage()

    // ** navigate into game
    navigate.push(`/qwertyuiop/gameEnter?room=${joinRoomData?.roomname}`);

  };

  // ** dispatch state 
  // ** generate random strings
  const dispatchState = () => {
    return new Promise((resolve, reject) => {
      try {
        const token = getRandomColor();
        const inGamePlayer = getRandomPlayerRoomCount();
        const selectedBoard = getRandomeBoard();
        const roomName = generateRandomRoomName();
        const roomAmmount = gameObj?.feeAmmount;

        // ** update game object
        dispatch(
          setGameObj({
            token: token,
            inGamePlayer: inGamePlayer,
            feeType: "coin",
            feeAmmount: roomAmmount,
            selectedBoard: selectedBoard,
          })
        );

        resolve({
          token: token,
          inGamePlayer: inGamePlayer,
          selectedBoard: selectedBoard,
          roomName,
          roomAmmount: roomAmmount
        });
      } catch (error) {
        reject(error);
      }
    });
  };

  // ** create game
  const createNewGame = (isFound) => {
    // ** dispatch state promiese
    dispatchState()
      .then((status) => {
        if (status) {
          const { roomName, inGamePlayer } = status;
          if (isFound) {
            publish(ludo_createRoom, {
              roomname: roomName,
              maxPlayers: inGamePlayer,
              ttl: 10,
              isVisible: false,
              roomPassword: "abc123",
            });
          }

    
          // ** navigate into game
          navigate.push(`/qwertyuiop/gameEnter?room=${roomName}`);

          //** clear out session */
          resetSessionStorage();
 
          setSessionStorageItem("savePrevQuickPlay",roomName);
        }
        // ** publish create room event
      })
      .catch((err) => {
        // console.log("error > ", err);
      });
  };

  return (
    <ModalWrapper
      visible={modalShow}
      onClose={onClose}
      customStyles={{
        width: "20%",
        height: "35%",
      }}
      title="Select Price"
      enterAnimation="zoom"
      leaveAnimation="zoom"
      id="select-price-modal"
    >
        <div className='py-2'>
        <CoinCounterCard />
        </div>
        <div className='py-2 flex justify-center'>
        <Button
          text="Start"
          className="text-shadow text-sm lg:text-md text-yellow-900"
          handleClick={createQuickPlayGame}
        />
        </div>
    </ModalWrapper>
  );
}

export default QuickTypeModal
