
import toast from "react-hot-toast";
import { setSessionStorageItem } from "./sessionStorageUtils";

export function generateRandomRoomName(type = "roomName") {
    // Define the character pool for the room name
    const characters = "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789";
  
    // Set the desired room name length
    const roomNameLength = type === 'password' ? 8 : 4;
  
    // Create an empty string to store the room name
    
    let roomName = type === 'password' ? "" : "room_";
  
    // Loop to generate random characters and build the room name
    for (let i = 0; i < roomNameLength; i++) {
      const randomIndex = Math.floor(Math.random() * characters.length);
      roomName += characters.charAt(randomIndex);
    }
  
    // Return the generated room name
    return roomName; 
}

export function generateRoomCode(length) {
  const digits = '0123456789';
  let code = '';
  for (let i = 0; i < length; i++) {
    code += digits[Math.floor(Math.random() * 10)];
  }
  return code;
}

export function getRandomPlayerRoomCount() {
   // Generate a random integer between 0 and 2 (inclusive)
  const randomInteger = Math.floor(Math.random() * 3); // 0, 1, or 2
  
  // Add 2 to the random integer to get a number between 2 and 4 (inclusive)
  const randomNumber = randomInteger + 2;

  return randomNumber;
}

export function getRandomColorByExistingColor(tokenArr) {
   const colors = ['green','yellow','blue','red'];
   const filterTokens = colors.filter(color => !tokenArr.includes(color));
   const randomIndex = Math.floor(Math.random() * filterTokens.length);
   return filterTokens[randomIndex];
}

export function getRandomColor() {
  const colors = ['green','yellow','blue','red'];
  const randomIndex = Math.floor(Math.random() * colors.length);
  return colors[randomIndex];
}

export function getRandomeBoard() {
  const boards = ['default','nature'];
  const randomIndex = Math.floor(Math.random() * boards.length);
  return boards[randomIndex];
}


export function mutatePlayerObject(p1, p2) {
  const MergePlayers = [...p1,...p2];
   window.addEventListener('resize' , () => {
    var x = window.matchMedia("(max-width: 768px)");
    if(x.matches) {
       let temp = MergePlayers[1];
       MergePlayers[1] = MergePlayers[2];
       MergePlayers[2] = temp;
    }
   });
   return MergePlayers;
}

const color = {
   'error' : 'red',
   'success' : 'green'
}
export function showToast(type,message) {
    let style = {
      border: `1px solid ${color[type]}`,
      padding: '.5rem',
      color: '#000',
    }
    if(type===null) {
      toast(message, {
        icon: '',
        style: {
          ...style,
          color : '#fff',
          fontWeight : '200',
          background : 'rgb(76 0 255 / 50%)'
        },
        position: "top-right"
      });
    } else if(type === 'info') {
      toast(message, {
        icon: '✨',
        // duration : 500,
        style: {
          ...style,
          color : '#000',
          fontWeight : '400',
          background : '#fff'
        },
        position: "top-right"
      });
    } else {
      toast[type](message , {
        duration : 5000,
        style: style,
        position: "top-right"
      });
    }
}


  


export const delay = async (cb,duration = 1000) => {
  // console.log("cab callend....")
  await new Promise((resoleve) => setTimeout(resoleve, duration));
  if(cb) cb();
}

export function getTime(time) {
  if(time === 0)  {
    return {
      minute : 0,
      second : 0,
    };
  }
  let minute = Math.floor((time / 1000 / 60) % 60);
  let second = (time / 1000) % 60;
  return {
    minute,
    second,
  };
}


export function updateTimeStamp() {
  let now = new Date();
  now.setSeconds(now.getSeconds() + 30);
  let currTimestamp = now.getTime();
  setSessionStorageItem('timeStamp', currTimestamp);
}

export function getWiningTime() {
  const twintySecond = 1000 * 20;
  const currentTime = new Date().getTime();
  const endTime = currentTime + twintySecond;
  return {
    startTime:currentTime,
    endTime
  }
}









