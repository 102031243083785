import React, { useEffect, useState } from "react";
import ModalWrapper from "../../../../components/Modal/ModalWrapper";
import { TOKEN } from "../../../../utils/tokenConstant";
import Token from "../TokenSelect/Token";
import { handleUpdateState } from "../../../helper";
import Button from "../../../../components/button/Button";
import { useDispatch, useSelector } from "react-redux";
import { socket } from "../../../../socket/socket";
import { RoomListing, SocketjoinRoom } from "../../../../constants/socketKeys";
import { publish } from "../../../../CustomEvents/event";
import { useHistory } from "react-router-dom";
import { setGameObj, setSingleLobbyData, setUserData } from "../../../../feature/slice/gameSlice";
import { getSessionStorageItem, resetSessionStorage, setSessionStorageItem } from "../../../../utils/sessionStorageUtils";
import { showToast } from "../../../../utils";
import Spinner from "../../../../components/Spinner/Spinner";
import { useRoomListing } from "../../../../hooks/useRoomListing";

function SelectPawns({ modalShow, onClose }) {
  const [selected, setSelected] = useState(false);
  const [tokens, setTokens] = useState([
    {
      color: "green",
      selected: false,
      tokenImage: TOKEN["green"],
      hash: "#00FF19",
    },
    {
      color: "blue",
      selected: false,
      tokenImage: TOKEN["blue"],
      hash: "#00A3FF",
    },
    {
      color: "yellow",
      selected: false,
      tokenImage: TOKEN["yellow"],
      hash: "#F2B901",
    },
    {
      color: "red",
      selected: false,
      tokenImage: TOKEN["red"],
      hash: "#FF0000",
    },
  ]);
  // const [color,setSelectedColor] = useState("");
  const { singleLobbyData,gameMode } = useSelector((state) => state.game);
  const navigate = useHistory();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);
  const [refetch, setRefetch] = useState(false);
  const [rooms] = useRoomListing(modalShow,refetch);
  const [color,setcolor] = useState(null)

  useEffect(() => {
    let timeout = null;
    if(modalShow) {
      timeout = setTimeout(() => {
         setRefetch(true)
         setLoading(false)
       }, 3000);
    }
  },[modalShow]);


  useEffect(() => {
    console.log("fetch rooms>>>",rooms);
    if(gameMode?.name !== 'Private') {
      dispatch(setSingleLobbyData(rooms?.find(room => room?.roomname === singleLobbyData?.roomname)))
    }
  },[rooms, refetch, gameMode?.name, dispatch, singleLobbyData?.roomname])

  //** handle available tokens **/
  useEffect(() => {
    if (singleLobbyData) {
      console.log("singleLobbyData",singleLobbyData)
      const engagedToken =
        singleLobbyData?.roomCustomPropertiesForLobby?.tokenArr;
      if (engagedToken?.length > 0) {
        setTokens((prev) => {
          return prev.filter((token) => {
            if (!engagedToken.includes(token.color)) {
              return token;
            }
          });
        });
        // setSelected(false);
      } else {
        //  showToast("error", "Let the first player to join");
         onClose();
      }
    }
  }, [singleLobbyData,refetch]);

  // ** haldle Select Color **
  const SelectColor = (token) => {
    // dispatch(setUserData({color : token.color}))
    setSelected(true);
    publish(RoomListing);
    setcolor(token.color);
    const boardType = singleLobbyData?.roomCustomPropertiesForLobby?.boardType;
    const roomAmmount = singleLobbyData?.roomCustomPropertiesForLobby?.roomAmmount;
    dispatch(setGameObj({ token: token.color, selectedBoard: boardType ,roomAmmount : roomAmmount }));
    setTokens((prev) => handleUpdateState(prev, token, "token"));
  };

  //** handle join rooms */
  const handleJoinRoom = () => {
    if (!selected) {
      showToast("error", "Select your token first!");
      return;
    }
    const engagedToken =
        singleLobbyData?.roomCustomPropertiesForLobby?.tokenArr;
        console.log("engagedToken",rooms);

    if(engagedToken.includes(color)) {
        showToast("error", "This color is already been occupied");
        return;
    }


    const joinRoomData = {
      roomname: singleLobbyData?.roomname,
      nickname: "user00" + `${Math.floor(Math.random() * 100)}`,
    };

    // publish(joinRoom, joinRoomData);

    //** clear out session */
    resetSessionStorage();
    // ** navigate into game
    navigate.push(`/qwertyuiop/gameEnter?room=${joinRoomData?.roomname}`);
  };

  return (
    <ModalWrapper
      visible={modalShow}
      onClose={onClose}
      customStyles={{
        width: "30%",
        height: "35%",
      }}
      title="Select Color"
      enterAnimation="zoom"
      leaveAnimation="zoom"
      id="select-pawn-modal"
    >
      <div className="px-2 py-5 flex w-7/12 m-auto justify-around">
        {
          loading ? <Spinner /> : ( 
            <>
              {tokens.map((token) => (
              <Token token={token} key={token.color} SelectColor={SelectColor} />
              ))}
            </>
          )
        }
       
      </div>
      <div className="py-2 w-full flex justify-center items-center">
        <Button
          text="Start"
          className="text-sm lg:text-xl text-yellow-900"
          handleClick={handleJoinRoom}
        />
      </div>
    </ModalWrapper>
  );
}

export default SelectPawns;
