import React, { useContext, useEffect } from "react";
import "./index.css";
import "./ludo.css";
import { AuthContext } from "../../../context/Auth";
import { useHistory, useLocation } from "react-router-dom/cjs/react-router-dom.min";
import {socketEvents} from "./socket/socketEvents";
import './socket/subscribeEvent.js'
socketEvents();

export const LudoLayout = ({ children }) => {
  const auth = useContext(AuthContext);
  const history = useHistory();
  const loc = useLocation();

  console.log("loc",loc)

  useEffect(() => {
    if (!auth.userLoggedIn) {
      history.push({ pathname: "/", search: "login" });
      document.body.style.overflowY = "auto";
    }
  }, [auth.userLoggedIn, history, loc.pathname]);
  return <div className="ludo-layout">{children}</div>;
};