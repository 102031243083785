import React, { useState, useEffect, useContext } from "react";
import PropTypes from "prop-types";
import { makeStyles, Box } from "@material-ui/core";
import NavBar from "./NavBar";
import TopBar from "./TopBar";
import { useLocation } from "react-router-dom";
import { AuthContext } from "src/context/Auth";
import { useHistory } from "react-router-dom";
import Footer from "./Footer";
import { Helmet } from "react-helmet";
const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: "#141627",
    display: "flex",
    height: "100%",
    overflow: "hidden",
    width: "100%",
  },
  wrapper: {
    // display: "flex",
    flex: "1 1 auto",
    overflow: "hidden",
    // paddingTop: 56,
    [theme.breakpoints.up("lg")]: {
      paddingLeft: 230,
    },
  },
  contentContainer: {
    display: "flex",
    flex: "1 1 auto",
    overflow: "hidden",
    // background: "rgba(2, 1, 3, 0.85)",
    background: "#141627",
    color: "#fff",
    minHeight: "calc(100vh - 62px)",
    padding: "60px 0px 0px",
  },
  content: {
    flex: "1 1 auto",
    height: "100%",
    overflow: "hidden",
    position: "relative",
    WebkitOverflowScrolling: "touch",
    padding: "50px 20px 0px",
    [theme.breakpoints.down("xs")]: {
      padding: "40px 16px 10px ",
    },
    "& .relativeClx": {
      position: "relative",
      zIndex: "11",
    },
  },
}));

const DashboardLayout = ({ children }) => {
  const classes = useStyles();
  const history = useHistory();
  const auth = useContext(AuthContext);
  const location = useLocation();
  const [isMobileNavOpen, setMobileNavOpen] = useState(false);
  const [currentUrl, setCurrentUrl] = useState("")

  useEffect(() => {
    setCurrentUrl(window.location.href)
  }, [window.location.href])



  const loadZendeskScript = () => {
    if (document.getElementById('ze-snippet')) return;
    const script = document.createElement('script');
    script.id = 'ze-snippet';
    script.src = 'https://static.zdassets.com/ekr/snippet.js?key=f3c3ec83-a4d4-4f80-906e-9e3b568f54c0';
    script.async = true;
    script.onload = () => {
      window.zE('messenger', 'close');
      // window.zE('messenger', 'show');
    };
    document.head.appendChild(script);
  };

  useEffect(() => {
    loadZendeskScript()
  }, [])



  useEffect(() => {
    auth.getUserWallletApi();
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
    if (window.location.pathname === "/" && localStorage.getItem("ADMIN")) {
      history.push("/");
      localStorage.removeItem("ADMIN");
    }
    if (
      auth.ownerAddress !== auth.account &&
      localStorage.removeItem("ADMIN")
    ) {
      history.push("/");
      localStorage.removeItem("ADMIN");
    }
  }, [window.location.pathname, auth.account]);


  useEffect(() => {
    auth.getUserWallletApi();
  }, []);


  return (
    <div className={classes.root}>
      <Helmet>
        <meta property="og:title" content="Referral - BetByHeart" />
        <meta property="og:type" content="website" />
        <meta property="og:url" content={currentUrl} />
        <meta property="og:image" content="https://betbyheart.com/images/favicon.png" />
        <meta property="og:description" content="Join BetByHeart with this referral link!" />
        <meta property="og:site_name" content="BetByHeart" />

        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:site" content="@betbyheart" />
        <meta name="twitter:title" content="Referral - BetByHeart" />
        <meta name="twitter:description" content="Join BetByHeart with this referral link!" />
        <meta name="twitter:image" content="https://betbyheart.com/images/favicon.png" />
      </Helmet>
      <TopBar onMobileNavOpen={() => setMobileNavOpen(true)} />
      <NavBar
        onMobileClose={() => setMobileNavOpen(false)}
        openMobile={isMobileNavOpen}
      />
      <div className={classes.wrapper}>
        <div className={classes.contentContainer}>
          <div className={classes.content} id="main-scroll">
            {/* <img
              src="images/backgroundlayout.png"
              alt="image"
              className="layoubackgroundImg"
            /> */}
            <Box className="relativeClx">{children}</Box>
          </div>
        </div>
        <Footer />
      </div>
    </div>
  );
};

DashboardLayout.propTypes = {
  children: PropTypes.node,
};

export default DashboardLayout;
