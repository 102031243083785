import { publish } from "../CustomEvents/event";
import { OnConnectToMaster_ludo, OnLocalRoomListUpdate, OnPlayerEnterRoom, OnPlayerLeftRoom, OnPlayerList, OnRoomListing, OnRoomPropertiesChange, ScoketleaveRoom, SocketOnPlayerEnterRoom, SocketOnPlayerLeftRoom, SocketOnRoomListUpdate, SocketOnRoomPropertiesChange, SocketPlayerList, SocketRoomListing, ludo_SocketcreateRoom, SocketgetRoomProperties, ludo_SocketjoinRoom, SocketraiseEvent, SocketreJoinRoom, SocketsetRoomProperties, ludo_onCreateRoom, onGetRoomProperties, ludo_onJoinRoom, onJoinRoomFailed, onRaiseEvent } from "../constants/socketKeys";
import { delay, showToast } from "../utils";
import { socket } from "./socket";


export const socketEvents = () => {



    socket.on('connect', () => {
        console.warn("ludo client connection to this socket.id", socket.id);
        sessionStorage.setItem('isConnected', true);
        publish(OnConnectToMaster_ludo, socket.id);
    });

    socket.on('disconnect', () => {
        // console.log('Socket.IO disconnected');
        showToast('error', 'Server disconnected');
    });
    
    // ** on Create room
    socket.on(ludo_SocketcreateRoom, function (msg) {
        console.log('SocketcreateRoom from server:', msg);
        // createdRoom = (msg)
        publish(ludo_onCreateRoom, msg);
        sessionStorage.setItem('isConnected', false);
    });

    // ** on Room Join
    socket.on(ludo_SocketjoinRoom, function (msg) {
        console.log('SocketjoinRoom from server:', msg);
        if (msg.success === false) {
            // console.log('SocketjoinRoom failed server:', msg);
            // showToast('error',msg.message)  
            publish(onJoinRoomFailed, msg);
        } else {
           publish(ludo_onJoinRoom, msg)
        }
    });

    // ** on Re-Room Join
    socket.on(SocketreJoinRoom, function (msg) {
        // console.log('SocketreJoinRoom from server:', msg);
        if (msg.success == false) {
            // console.log('SocketjoinRoom failed server:', msg);
            publish(onJoinRoomFailed, msg);
            
        } else {
            publish(ludo_onJoinRoom, msg);
        }
    });

     //** on player left room */
    socket.on(SocketOnPlayerLeftRoom, function (msg) {
        // console.log('OnPlayerLeftRoom from server:', msg);
        publish(OnPlayerLeftRoom, msg.data)
    });
    
    //** on player leave room */
    socket.on(ScoketleaveRoom, function (msg) {
        // showToast('info',"Player leave the room");
        // console.log('ScoketleaveRoom from server:', msg);
    });
    
    // ** GET ROOM LISTING
    socket.on(SocketRoomListing, function (msg) {
        // console.log('SocketRoomListing from server:', msg);
        publish(OnRoomListing, msg);
    });

    // ** GET PLAYER LISTING OF LOCAL PLAYER
    socket.on(SocketPlayerList, (players) => {
        publish(OnPlayerList, players);
    })

    // **  GET ROOM PROPERTIES
    socket.on(SocketgetRoomProperties, (data) => {
        // console.log("SocketgetRoomProperties>>>>",data)
        publish(onGetRoomProperties, data);
    })

    //** ON PLAYER ENTER ROOM */
    socket.on(SocketOnPlayerEnterRoom, function (msg) {
        console.warn('OnPlayerEnterRoom from server ludo:', msg);
        publish(OnPlayerEnterRoom, msg.data) 
    });

    //** ON ROOM PROPERTY CHANGE */
    socket.on(SocketOnRoomPropertiesChange, function (msg) {
        console.log('SocketOnRoomPropertiesChange from server:', msg);
        publish(OnRoomPropertiesChange, msg)
    });

    // ** ON SOCKET RAISE EVENT
    socket.on(SocketraiseEvent, function (msg) {
        // console.log('SocketraiseEvent from server:', msg);
        if(msg.success === false) {
        //    showToast('error', msg.message);
           return;
        }
        publish(onRaiseEvent, msg)
    });

    // ON ROME LIST UPDATE
    socket.on(SocketOnRoomListUpdate , (data) => {
        // console.log('SocketOnRoomListUpdate from server:', data);
        publish(OnLocalRoomListUpdate)
    })
    
}


